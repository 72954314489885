import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { device } from "./device"
import Logo from "../assets/logo.svg"

const color = "hsla(0, 0%, 0%, 0.575);"
const smokeGrey = "#878789"
const spacing = "3rem clamp(1.2rem, 4vw, 6rem);"
const spacingLR = "clamp(1.2rem, 4vw, 6rem);"
const MainMenuWrapper = styled.div`
  margin: 0;
  padding: 3rem ${spacingLR};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  justify-content: space-between;
  position: fixed;
  font-size: clamp(1.15em, 2.564vw, 1.25em);
  left: 0;
  top: 0;
  background: ${smokeGrey};
  z-index: 9;
  transition: transform 750ms;
  transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(-100%)")};
  @media ${device.tablet} {
    width: 25%;
  }
  @media ${device.laptop} {
    width: 25%;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li span {
    transition: color 500ms ease-out;
  }
  li span:hover {
    color: white;
  }
`

const MenuIcon = styled.div`
  position: fixed;
  right: ${spacingLR};
  background: ${color};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.75em;
  height: 1.5em;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 99;

  div {
    width: 1.75em;
    height: 2px;
    transform-origin: 1px;
    position: relative;
    transition: opacity 300ms, transform 300ms;
    transform-origin: center;
    background: ${color};
    :first-of-type {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }

    :nth-of-type(2) {
      transform: ${({ nav }) => (nav ? "rotate(360deg)" : "rotate(0)")};
    }
    :nth-of-type(3) {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }
  }
`

const Header = ({ siteTitle }) => {
  useEffect(() => {
    window.addEventListener("scroll", event => {
      const mobileMenu = document.querySelector(".header")
      const currentScroll = window.pageYOffset
      if (currentScroll >= window.innerHeight) {
        mobileMenu.classList.add("ebony")
      }
      if (currentScroll <= window.innerHeight) {
        mobileMenu.classList.remove("ebony")
      }
    })
  })

  const [nav, showNav] = useState(false)

  const toggleClass = () => {
    showNav(!nav)
  }

  const closeMenu = () => {
    showNav(!nav)
  }
  return (
    <header
      className="header"
      css={css`
        marginbottom: 1.45em;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: ${spacing};
        z-index: 2;
        width: 100%;
        position: absolute;
        & a {
          color: ${color};
        }
      `}
    >
      <h1
        css={css`
          margin: 0;
          padding-bottom: 0.5rem;
          font-size: 1.6rem;
          width: 200px;
        `}
      >
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
        >
          <Logo />
          {/* Corrigan
          <span
            style={{
              fontFamily: `GoodSans-Thin, sans-serif`,
            }}
          >
            Gore
          </span> */}
        </Link>
      </h1>

      <MenuIcon
        id="mobile-menu"
        className={nav ? "active" : null}
        nav={nav}
        onClick={() => showNav(!nav) && toggleClass}
      >
        <div />
        <div />
        <div />
      </MenuIcon>

      <MainMenuWrapper className={nav ? "active" : null} nav={nav}>
        <ul>
          <li>
            <Link to="/" title="Home">
              <span>Home</span>
            </Link>
          </li>
          <li>
            <AnchorLink to="/#projects" stripHash>
              <span
                aria-hidden="true"
                onClick={closeMenu}
                onKeyDown={closeMenu}
              >
                Projects
              </span>
            </AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#about" stripHash>
              <span
                aria-hidden="true"
                onClick={closeMenu}
                onKeyDown={closeMenu}
              >
                About
              </span>
            </AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#services" stripHash>
              <span
                aria-hidden="true"
                onClick={closeMenu}
                onKeyDown={closeMenu}
              >
                Services
              </span>
            </AnchorLink>
          </li>
          <li>
            <Link to="/contact/">
              <span
                aria-hidden="true"
                onClick={closeMenu}
                onKeyDown={closeMenu}
              >
                Contact
              </span>
            </Link>
          </li>
          {/* <li>
            <Link to="/contact">
              <span>Contact</span>
            </Link>
          </li> */}
        </ul>
        <div
          css={css`
            font-size: 72.5%;
            margin-top: 1.5rem;
          `}
        >
          <ul>
            <li>
              <a href="https://twitter.com/CorriganGore" target="blank">
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/corrigangore"
                target="blank"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/corrigan_gore/" target="blank">
                Instagram
              </a>
            </li>
          </ul>

          {/* <ul>
            <li>
              <a href="tel: 00442037733637">+44 (0)20 3773 3637</a>
            </li>
            <li>
              <a href="mailto: info@corrigangore.com"> info@corrigangore.com</a>
            </li>
          </ul> */}
        </div>
      </MainMenuWrapper>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
