/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Header from "./header"
import "./layout.scss"
import { device } from "./device"

const smokeGrey = "hsla(240, 1%, 53%, 1)"
const spacing = "1.5rem clamp(1.2rem, 4vw, 6rem) clamp(1.2rem, 4vw, 6rem)"
const color = "hsla(0, 0%, 0%, 0.575);"
// const spacingH = "clamp(1.2rem, 4vw, 6rem)"

const Container = styled.div`
  display: flex;
  padding: ${spacing};
  // background-color: ${smokeGrey};
  color: ${color};
  flex-direction: column;
  align-items: flex-start;
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
  }
  & a {
    color: ${color};
    cursor: pointer;
  }
`

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div
      className="app"
      css={css`
        display: flex;
        min-height: 100vh;
        flex-direction: column;
      `}
    >
      <Header siteTitle="Corrigan Gore" />
      <main
        css={css`
          flex-grow: 1;
        `}
      >
        {" "}
        {children}
      </main>
      <footer>
        <Container>
          <div
            css={css`
              font-size: 1.7em;
              padding-bottom: 1.5rem;
              @media ${device.tablet} {
                padding-bottom: 0;
              }
            `}
          >
            <span
              css={css`
                font-family: "GoodSans-Medium", sans-serif;
              `}
            >
              Corrigan
            </span>
            Gore
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              @media ${device.tablet} {
                text-align: right;
              }
            `}
          >
            <a href="tel: 00442037733637">
              <span
                css={css`
                  font-family: "GoodSans-Medium", sans-serif;
                  padding-right: 0.5rem;
                `}
              >
                London
              </span>
              +44 (0)20 3773 3637
            </a>
            <a href="tel: 00441225800161">
              <span
                css={css`
                  font-family: "GoodSans-Medium", sans-serif;
                  padding-right: 0.5rem;
                `}
              >
                Bath
              </span>
              +44 (0)1225 800 161
            </a>
            <a
              css={css`
                padding-top: 1.5rem;
              `}
              href="mailto:info@corrigangore.com"
            >
              info@corrigangore.com
            </a>
          </div>
        </Container>
        <div
          css={css`
              padding: ${spacing};
              font-size: 0.85rem;
              // background: ${smokeGrey};
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              @media ${device.tablet} {
                flex-direction: row;
                justify-content: space-between;
              }
            }
            `}
        >
          <div>
            <ul
              css={css`
                  display: flex;
                  flex-direction: column;
                  list-style: none;
                  margin: 0 0 1.5rem;
                  padding: 0;
                  @media ${device.tablet} {
                    margin: 0;
                  }

                  li {
                    margin-bottom: 0.25rem;
                  }
                  @media ${device.tablet} {
                    flex-direction: row;

                    li {
                      padding: 0 0.3rem 0.25rem 0.3rem;
                    }
                  }
                }
                `}
            >
              <li>
                <a href="https://twitter.com/CorriganGore" target="blank">
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/corrigangore"
                  target="blank"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/corrigan_gore/"
                  target="blank"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div>
            {" "}
            © {new Date().getFullYear()} Corrigan Gore. Design{" "}
            <a href="https://studiohickie.com">StudioHickie.</a>
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
